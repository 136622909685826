<template>
  <v-container>
    <div>
      <UploadPhotoDialog
        ref="upload"
        @photo-uploaded="updateCustomerAlbumPhotoComponent"
      />
      <CreateCustomerAlbumDialog ref="create" @album-created="getDataFromAPI" />
      <EditCustomerAlbumDialog ref="edit" @album-edited="getDataFromAPI" />
      <ConfirmDeleteDialog ref="confirm" />
    </div>
    <v-card :loading="loading">
      <v-toolbar color="primary">
        <v-toolbar-title>
          <v-icon>
          mdi-image-multiple
        </v-icon>
        รูปภาพ
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          small
          color="secondary"
          class="ma-2"
          outlined
          @click="openCreateCustomerAlbumDialog(id)"
        >
          <v-icon small>
            mdi-plus
          </v-icon>
          Add Album
        </v-btn>         
      </v-toolbar>
      <!-- <v-card-title>
        <v-icon>
          mdi-image-multiple
        </v-icon>
        รูปภาพ
        <v-spacer />
        <v-btn
          small
          color="secondary"
          class="ma-2"
          outlined
          @click="openCreateCustomerAlbumDialog(id)"
        >
          <v-icon small>
            mdi-plus
          </v-icon>
          Add Album
        </v-btn>
      </v-card-title>
      <v-divider /> -->
      <v-card-text v-if="customerAlbums.length !== 0">
        <v-card>
          <v-tabs v-model="tab" color="secondary">
            <v-tab
              show-arrows
              v-for="customerAlbum in customerAlbums"
              :key="customerAlbum.id"
            >
              {{ customerAlbum.name }}
            </v-tab>
          </v-tabs>
          <v-divider />
          <v-tabs-items v-model="tab" touchless>
            <v-tab-item
              v-for="customerAlbum in customerAlbums"
              :key="customerAlbum.id"
              :eager="true"
              touchless
            >
              <v-row>
                <v-col>
                  <v-btn
                    fab
                    small
                    color="secondary"
                    class="ma-2"
                    @click="openUploadPhotoDialog(customerAlbum)"
                  >
                    <v-icon>
                      mdi-file-upload
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-spacer />
                <v-col align="right">
                  <v-btn
                    fab
                    small
                    color="secondary"
                    class="ma-2"
                    @click="openEditCustomerAlbumDialog(customerAlbum)"
                  >
                    <v-icon>
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                  <v-btn
                    fab
                    small
                    color="secondary"
                    class="ma-2"
                    @click="confirmDeleteAlbum(customerAlbum)"
                  >
                    <v-icon>
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <CustomerAlbumPhoto
                :id="customerAlbum.id.toString()"
                :key="CustomerAlbumPhotoComponentKey"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import UploadPhotoDialog from "./UploadPhotoDialog";
import CreateCustomerAlbumDialog from "./CreateCustomerAlbumDialog";
import EditCustomerAlbumDialog from "./EditCustomerAlbumDialog";
import ConfirmDeleteDialog from "../Shared/ConfirmDeleteDialog";
import CustomerAlbumPhoto from "../CustomerAlbumPhoto/CustomerAlbumPhoto";
export default {
  name: "CustomerAlbumCard",
  components: {
    CustomerAlbumPhoto,
    CreateCustomerAlbumDialog,
    EditCustomerAlbumDialog,
    UploadPhotoDialog,
    ConfirmDeleteDialog,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    id: {
      type: String,
    },
  },
  data: function() {
    return {
      tab: null,
      customerAlbums: [],
      loading: false,
      deleteAlbumResponse: {},
      CustomerAlbumPhotoComponentKey: 0,
    };
  },
  computed: {},
  created() {
    this.getDataFromAPI();
  },
  methods: {
    getDataFromAPI() {
      this.loading = true;
      axios
        .get("/customer/" + this.id + "/customer-album")
        .then((response) => {
          // JSON responses are automatically parsed.
          this.customerAlbums = response.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    openUploadPhotoDialog: async function(customerAlbum) {
      await this.$refs.upload.open(customerAlbum);
    },
    openEditCustomerAlbumDialog: async function(customerAlbum) {
      await this.$refs.edit.open(customerAlbum);
    },
    openCreateCustomerAlbumDialog: async function(id) {
      await this.$refs.create.open(id);
    },
    confirmDeleteAlbum: async function(customerAlbum) {
      if (
        await this.$refs.confirm.open(
          "ยืนยันการลบอัลบั้ม.",
          "คุณแน่ใจที่จะลบอัลบั้มนี้?"
        )
      ) {
        this.deleteAlbum(customerAlbum);
      }
    },
    deleteAlbum: async function(customerAlbum) {
      this.loading = true;
      axios
        .delete("/customer-album/" + customerAlbum.id)
        .then((response) => {
          // JSON responses are automatically parsed.
          this.deleteAlbumResponse = response.data;
          this.loading = false;
          if (this.deleteAlbumResponse.status === "success") {
            this.getDataFromAPI();
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    updateCustomerAlbumPhotoComponent() {
      this.CustomerAlbumPhotoComponentKey += 1;
    },
  },
};
</script>
