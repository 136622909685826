<template>
  <v-container>
      <v-row wrap dense>
      <v-col dense cols="12" xs="12" sm="12" md="12" lg="4" xl="4" grow pa-1>
        <customer-data-card :customer="customer" :loading="loading" />
      </v-col>
      <v-col dense cols="12" xs="12" sm="12" md="12" lg="8" xl="8" grow pa-1>
        <customer-location-card :customer="customer" :loading="loading" />
      </v-col>
    </v-row>
    <v-row wrap dense>
      <v-col dense cols="12" xs="12" sm="12" md="12" lg="6" xl="6" grow pa-1>
        <customer-contact-card :id="id" />
      </v-col>
      <v-col dense cols="12" xs="12" sm="12" md="12" lg="6" xl="6" grow pa-1> 
        <customer-meeting-card  :id="id" />
      </v-col>
    </v-row>
    <v-row wrap dense>
      <v-col dense cols="12" xs="12" sm="12" md="12" lg="12" xl="12" grow pa-1> 
        <CustomerCRMDataCard  :id="id" />
      </v-col>
    </v-row>
    <v-row wrap dense>
        <v-col dense cols="12" xs="12" sm="12" md="12" lg="12" xl="12" grow pa-1> 
        <customer-special-bill-data-card  :id="id" />
      </v-col>
    </v-row>
    <v-row wrap dense>
      <v-col dense cols="12" xs="12" sm="12" md="12" lg="12" xl="12" grow pa-1> 
        <CustomerSICard  :id="id" />
      </v-col>
    </v-row>
    <v-row wrap dense>
      <v-col dense cols="12" xs="12" sm="12" md="12" lg="12" xl="12" grow pa-1> 
        <customer-inventory-card  :id="id" />
      </v-col>
    </v-row>
    <v-row wrap dense>
      <v-col dense cols="12" xs="12" sm="12" md="12" lg="12" xl="12" grow pa-1> 
        <customer-album-card :id="id" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import CustomerDataCard from "./CustomerDataCard.vue";
import CustomerContactCard from "../../CustomerContact/CustomerContactCard.vue";
import CustomerMeetingCard from "../../CustomerMeeting/CustomerMeetingCard.vue";
import CustomerSICard from "../../CustomerSI/CustomerSICard.vue";
import CustomerCRMDataCard from "../../CustomerCRMData/CustomerCRMDataCard.vue";
import CustomerSpecialBillDataCard from "../../CustomerSpecialBillData/CustomerSpecialBillDataCard.vue";
// import CustomerUseServiceCard from "../../CustomerUseService/CustomerUseServiceCard.vue";
import CustomerInventoryCard from "../../CustomerInventory/CustomerInventoryCard.vue";
import CustomerLocationCard from "./CustomerLocationCard.vue";
import CustomerAlbumCard from "../../CustomerAlbum/CustomerAlbumCard.vue";
// import { mapActions, mapGetters } from 'vuex'
moment.locale("th");

export default {
  name: "CustomerShow",
  components: {
    CustomerDataCard,
    CustomerContactCard,
    CustomerMeetingCard,
    CustomerSICard,
    CustomerCRMDataCard,
    CustomerSpecialBillDataCard,
    // CustomerUseServiceCard,
    CustomerInventoryCard,
    CustomerLocationCard,
    CustomerAlbumCard
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    id: {
      type: String,
    },
  },
  data() {
    return {
      customer: {
        name: "",
        address: "",
        subdistrict: {
          district: {
            province: {},
          },
        },
        customerCategory: {},
        officeCentre: {},
      },
      loading: false,
    };
  },
  created() {
    this.getDataFromAPI();
  },
  methods: {
    getDataFromAPI() {
      this.loading = true;
      axios
        .get("/customer/" + this.id)
        .then((response) => {
          // JSON responses are automatically parsed.
          this.customer = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
  },
};
</script>
