<template>
  <v-container>
    <div>
      <CreateCustomerContactDialog
        ref="create"
        @customer-contact-created="getDataFromAPI"
      />
      <EditCustomerContactDialog
        ref="edit"
        @customer-contact-edited="getDataFromAPI"
      />
      <ConfirmDeleteDialog ref="confirm" />
    </div>
    <v-card :loading="loading">
      <v-toolbar color="primary">
        <v-toolbar-title>
          <v-icon medium>
          mdi-contacts
        </v-icon>
        ผู้ติดต่อ
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          small
          color="secondary"
          class="ma-2"
          outlined
          @click="openCreateCustomerContactDialog(id)"
        >
          <v-icon small>
            mdi-plus
          </v-icon>
          Add Contact
        </v-btn>
      </v-toolbar>

      <!-- <v-card-title>
        <v-icon medium>
          mdi-contacts
        </v-icon>
        ผู้ติดต่อ
        <v-spacer />
        <v-btn
          small
          color="secondary"
          class="ma-2"
          outlined
          @click="openCreateCustomerContactDialog(id)"
        >
          <v-icon small>
            mdi-plus
          </v-icon>
          Add Contact
        </v-btn>
      </v-card-title> -->
      <v-divider />
      <v-card-text v-if="loading == false && customerContacts.length !== 0">
        <v-list max-height="350" class="scroll">
          <v-list-item
            v-for="customerContact in customerContacts"
            :key="customerContact.id"
          >
            <!-- <v-list-item-avatar>
                <v-img :alt="`${customerContact.name} avatar`" :src="chat.avatar"></v-img>
              </v-list-item-avatar> -->
            <v-avatar color="grey" class="mx-2" size="40">
              <v-icon dark>
                mdi-account-circle
              </v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-subtitle>
                {{ customerContact.name }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <v-icon small dense>
                  mdi-briefcase
                </v-icon>
                <span> &nbsp;{{ customerContact.position }} </span>
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <v-icon small>
                  mdi-phone
                </v-icon>
                <span>
                  &nbsp;<a
                    class="blue--text"
                    :href="`tel:${customerContact.phone}`"
                    >{{ customerContact.phone }}</a
                  >
                </span>
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <v-icon small>
                  mdi-mail
                </v-icon>
                <span>
                  &nbsp;<a
                    class="blue--text"
                    :href="`mailto:${customerContact.email}`"
                    >{{ customerContact.email }}</a
                  >
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                icon
                @click="openEditCustomerContactDialog(customerContact)"
              >
                <v-icon small color="secondary">mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                icon
                @click="confirmDeleteCustomerContact(customerContact)"
              >
                <v-icon small color="secondary">mdi-delete</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import CreateCustomerContactDialog from "./CreateCustomerContactDialog";
import EditCustomerContactDialog from "./EditCustomerContactDialog";
import ConfirmDeleteDialog from "../Shared/ConfirmDeleteDialog";

moment.locale("th");
export default {
  name: "CustomerContactCard",
  components: {
    CreateCustomerContactDialog,
    EditCustomerContactDialog,
    ConfirmDeleteDialog,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    id: {
      type: String,
    },
  },
  data() {
    return {
      customerContacts: {},
      loading: false,
    };
  },
  created() {
    this.getDataFromAPI();
  },
  methods: {
    getDataFromAPI() {
      this.loading = true;
      axios
        .get("/customer/" + this.id + "/customer-contact")
        .then((response) => {
          // JSON responses are automatically parsed.
          this.customerContacts = response.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    openCreateCustomerContactDialog: async function(id) {
      await this.$refs.create.open(id);
    },
    openEditCustomerContactDialog: async function(customerContact) {
      await this.$refs.edit.open(customerContact);
    },
    confirmDeleteCustomerContact: async function(customerContact) {
      if (
        await this.$refs.confirm.open(
          "ยืนยันการลบผู้ติดต่อ.",
          "คุณแน่ใจที่จะลบผู้ติดต่อนี้?"
        )
      ) {
        this.deleteAlbum(customerContact);
      }
    },
    deleteAlbum: async function(customerContact) {
      this.loading = true;
      axios
        .delete("/customer-contact/" + customerContact.id)
        .then((response) => {
          // JSON responses are automatically parsed.
          this.deleteCustomerContactResponse = response.data;
          this.loading = false;
          if (this.deleteCustomerContactResponse.status === "success") {
            this.getDataFromAPI();
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.scroll {
  overflow-y: scroll;
}
</style>
