<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-toolbar dark :color="options.color" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold grey--text">
            แก้ไขข้อมูล CRM
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form">
            <v-row>
              <v-col>
                <v-text-field
                  v-model="customerCRMData.CA"
                  color="primary"
                  name="CA"
                  hint="CA"
                  label="CA"
                  clearable
                  @input="customerCRMData.CA === null ? customerCRMData.CA = null : customerCRMData.CA = customerCRMData.CA.toUpperCase()"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="customerCRMData.BA"
                  color="primary"
                  name="BA"
                  hint="BA"
                  label="BA"
                  type="number"
                  maxlength="12"
                  counter
                  :rules="customerCRMDataBARules"
                  clearable
                  @input="customerCRMData.BA === null ? customerCRMData.BA = null : customerCRMData.BA = customerCRMData.BA.toUpperCase()"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            class="body-2 font-weight-bold"
            outlined
            @click="save()"
            >save</v-btn
          >
          <v-btn
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click="cancel()"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";

export default {
  name: "CreateCustomerCRMDataDialog",
  props: {},
  data() {
    return {
      id: null,
      dialog: false,
      resolve: null,
      reject: null,
      customer: {
        name: null,
      },
      customerCRMData: {
        CA: null,
        BA: null,
      },
      options: {
        color: "primary",
        width: 400,
        zIndex: 200,
      },
      customerCRMDataCARules: [
        (value) => !!value || "CA is required",
        (value) => {
          const pattern = /^[A-Z,0-9,-]{1,17}$/;
          if (value) return pattern.test(value) || "Wrong CA format";
          else return true;
        },        
      ],
      customerCRMDataBARules: [
        (value) => !!value || "BA is required",
        (value) => {
          if (value) return value.length === 12 || "BA must have 12 characters";
          else return true;
        },
      ],
    };
  },
  created() {

  },
  methods: {
    open: function(customerCRMData) {
      this.dialog = true;
      this.customerCRMData = JSON.parse(JSON.stringify(customerCRMData));
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    save() {
      if (this.$refs.form.validate()) {
        delete this.customerCRMData.customer_id
        axios
          .put(
            "/customer-crm-data/" + this.customerCRMData.id,
            this.customerCRMData
          )
          .then((response) => {
            this.editCustomerCRMDataResponse = response.data;
            if (this.editCustomerCRMDataResponse.status === "success") {
              this.loading = false;
              this.resolve(true);
              this.dialog = false;
              this.$refs.form.reset();
              this.$emit("customer-crm-data-edited");
            }
          })
          .catch(function() {});
      }
    },
    cancel() {
      this.$refs.form.reset();
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>
