<template>
  <v-container>
    <div>
      <CreateCustomerSpecialBillDataDialog
        ref="create"
        @customer-special-bill-data-created="getDataFromAPI"
      />
      <EditCustomerSpecialBillDataDialog
        ref="edit"
        @customer-special-bill-data-edited="getDataFromAPI"
      />
      <ConfirmDeleteDialog ref="confirm" />
    </div>
    <v-card :loading="loading">
      <v-toolbar color="primary">
        <v-toolbar-title>
          <v-icon medium>
          {{ icon }}
        </v-icon>
        ข้อมูล ใบแจ้งหนี้พิเศษ
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          small
          color="secondary"
          class="ma-2"
          outlined
          @click="openCreateCustomerSpecialBillDataDialog(id)"
        >
          <v-icon small>
            mdi-plus
          </v-icon>
          Add Special Bill Data
        </v-btn>
      </v-toolbar>
      <!-- <v-card-title>
        <v-icon medium>
          {{ icon }}
        </v-icon>
        ข้อมูล ใบแจ้งหนี้พิเศษ
        <v-spacer />
        <v-btn
          small
          color="secondary"
          class="ma-2"
          outlined
          @click="openCreateCustomerSpecialBillDataDialog(id)"
        >
          <v-icon small>
            mdi-plus
          </v-icon>
          Add Special Bill Data
        </v-btn>
      </v-card-title>
      <v-divider /> -->
      <v-card-text v-if="loading == false && customerSpecialBillDatas.length !== 0">
        <v-data-table
          :headers="headers"
          :items="customerSpecialBillDatas"
          class="elevation-1"
          :footer-props="{ itemsPerPageOptions: [10, 50, 100] }"
          show-expand
          dense
        >
        <template v-slot:[`item.specialBillDatas.length`]="{ item }">
            <span>
              <v-chip small :color="getColor(item.specialBillDatas.length)" dark>
                {{ new Intl.NumberFormat("th").format(item.specialBillDatas.length) }}
              </v-chip>
            </span>
          </template>
        <template v-slot:[`item.action`]="{ item }">
            <v-btn icon @click="openEditCustomerSpecialBillDataDialog(item)">
              <v-icon small color="secondary">mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon @click="confirmDeleteCustomerSpecialBillData(item)">
              <v-icon small color="secondary">mdi-delete</v-icon>
            </v-btn>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length" class="pa-2">
                    <v-data-table
                      :headers="specialBillDatasHeaders"
                      :items="item.specialBillDatas"
                      :single-expand="true"
                      :items-per-page="-1"
                      hide-default-footer
                      dense
                      class="elevation-3"
                    >
                    </v-data-table>                   
                  </td>
                </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import CreateCustomerSpecialBillDataDialog from "./CreateCustomerSpecialBillDataDialog";
import EditCustomerSpecialBillDataDialog from "./EditCustomerSpecialBillDataDialog";
import ConfirmDeleteDialog from "../Shared/ConfirmDeleteDialog";
import { mdiReceiptTextOutline } from '@mdi/js';

moment.locale("th");
export default {
  name: "CustomerSpecialBillDataCard",
  components: {
    CreateCustomerSpecialBillDataDialog,
    EditCustomerSpecialBillDataDialog,
    ConfirmDeleteDialog,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    id: {
      type: String,
    },
  },
  data() {
    return {
      icon: mdiReceiptTextOutline,
      customerSpecialBillDatas: {},
      loading: false,
      headers: [
        {
          text: "CA",
          value: "CA",
          sortable: false,
          align: "center",
        },
        {
          text: "จำนวนบริการ",
          value: "specialBillDatas.length",
          sortable: false,
          align: "center",
        },
        {
          text: "",
          value: "action",
          sortable: false,
          align: "center",
        },
        // { text: "", value: "data-table-expand" },
      ],
      specialBillDatasHeaders: [
        {
          text: "Name",
          value: "name",
          sortable: false,
          align: "center",
        },
        {
          text: "วันที่ออกบิล",
          value: "billIssueDate",
          sortable: false,
          align: "center",
        },
        {
          text: "Service Number",
          value: "serviceNum",
          sortable: false,
          align: "center",
        },
        {
          text: "รหัสบริการ",
          value: "serviceCode",
          sortable: false,
          align: "center",
        },
        {
          text: "รายละเอียด",
          value: "priceDetail",
          sortable: false,
          align: "center",
        },
        {
          text: "ราคา",
          value: "price",
          sortable: false,
          align: "center",
        },
        {
          text: "ส่วนลด",
          value: "discount",
          sortable: false,
          align: "center",
        },
        {
          text: "ราคาหลังส่วนลด",
          value: "priceAfterDiscount",
          sortable: false,
          align: "center",
        },
        {
          text: "ภาษี",
          value: "tax",
          sortable: false,
          align: "center",
        },
        {
          text: "จำนวนเงินรวม",
          value: "priceTotal",
          sortable: false,
          align: "center",
        },
        {
          text: "รหัสส่วนงานให้ออกบิล",
          value: "billIssueOfficeCode",
          sortable: false,
          align: "center",
        },
      ],
    };
  },
  created() {
    this.getDataFromAPI();
  },
  methods: {
    getDataFromAPI() {
      this.loading = true;
      axios
        .get("/customer/" + this.id + "/customer-special-bill-data")
        .then((response) => {
          // JSON responses are automatically parsed.
          this.customerSpecialBillDatas = response.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    openCreateCustomerSpecialBillDataDialog: async function(id) {
      await this.$refs.create.open(id);
    },
    openEditCustomerSpecialBillDataDialog: async function(customerSpecialBillData) {
      await this.$refs.edit.open(customerSpecialBillData);
    },
    confirmDeleteCustomerSpecialBillData: async function(customerSpecialBillData) {
      if (
        await this.$refs.confirm.open(
          "ยืนยันการลบข้อมูลใบแจ้งหนี้พิเศษ",
          "คุณแน่ใจที่จะลบข้อมูลใบแจ้งหนี้พิเศษนี้?"
        )
      ) {
        this.deleteCustomerSpecialBillData(customerSpecialBillData);
      }
    },
    deleteCustomerSpecialBillData: async function(customerSpecialBillData) {
      this.loading = true;
      axios
        .delete("/customer-special-bill-data/" + customerSpecialBillData.id)
        .then((response) => {
          // JSON responses are automatically parsed.
          this.deleteCustomerSpecialBillDataResponse = response.data;
          this.loading = false;
          if (this.deleteCustomerSpecialBillDataResponse.status === "success") {
            this.getDataFromAPI();
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getColor(number) {
      if (number === 0) return "grey";
      else return "green";
    },
  },
};
</script>

<style>
.scroll {
  overflow-y: scroll;
}
</style>
