<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
    :style="{ zIndex: options.zIndex }"
  >
    <v-card>
      <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="text-body-2 font-weight-bold grey--text">
          สร้างผู้ติดต่อ.
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col>
              <v-text-field
                v-model.trim="customerContact.name"
                :rules="nameRules"
                color="secondary"
                name="name"
                hint="ชื่อ"
                label="ชื่อ"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model.trim="customerContact.position"
                :rules="positionRules"
                color="secondary"
                name="position"
                hint="ตำแหน่ง"
                label="ตำแหน่ง"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <vue-tel-input-vuetify
                :rules="phoneRules"
                required
                label="โทรศัพท์"
                placeholder="โทรศัพท์"
                clearable
                :maxLen="15"
                defaultCountry="TH"
                v-bind:onlyCountries="['TH']"
                mode="international"
                v-model="customerContact.phone"
              ></vue-tel-input-vuetify>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="customerContact.email"
                :rules="emailRules"
                color="secondary"
                name="email"
                hint="E-mail"
                label="E-mail"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          class="body-2 font-weight-bold"
          outlined
          @click="save()"
          >save</v-btn
        >
        <v-btn
          color="grey"
          text
          class="body-2 font-weight-bold"
          @click="cancel()"
          >Cancel</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "CreateCustomerContactDialog",
  components: {},
  props: {},
  data() {
    return {
      id: null,
      dialog: false,
      resolve: null,
      reject: null,
      customer: {
        name: null,
      },
      customerContact: {},
      nameRules: [(value) => !!value || "Name is required"],
      positionRules: [(value) => !!value || "Position is required"],
      phoneRules: [(value) => !!value || "Phone number is required"],
      emailRules: [
        (value) =>
          !value ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) ||
          "E-mail must be valid",
      ],
      options: {
        color: "primary",
        width: 400,
        zIndex: 200,
      },
    };
  },
  created() {},
  methods: {
    open: function(id) {
      this.dialog = true;
      this.id = id;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    save() {
      if (this.$refs.form.validate()) {
        axios
          .post(
            "/customer/" + this.id + "/customer-contact",
            this.customerContact
          )
          .then((response) => {
            this.createCustomerContactResponse = response.data;
            if (this.createCustomerContactResponse.status === "success") {
              this.loading = false;
              this.resolve(true);
              this.dialog = false;
              this.$refs.form.reset();
              this.$emit("customer-contact-created");
            }
          })
          .catch(function() {});
      }
    },
    cancel() {
      this.$refs.form.reset();
      this.customerContact.name = "";
      this.customerContact.position = "";
      this.customerContact.phone = "";
      this.customerContact.email = "";
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
.v-input.v-input--is-label-active.v-input--is-dirty.v-input--is-focused.theme--light.v-text-field.v-text-field--is-booted.v-select.primary--text {
  color: grey !important;
  caret-color: grey !important;
}
</style>
