<template>
  <v-dialog v-model="dialog" persistent max-width="800px">
    <v-card>
      <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="text-body-2 font-weight-bold grey--text">
          สร้างงาน SI.
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col>
              <v-select
                :items="customerSICategories"
                v-model="customerSI.customerSICategory_id"
                name="customerSICategory"
                item-text="name"
                item-value="id"
                label="ประเภทงาน SI"
                color="secondary"
                required
                :rules="[(v) => !!v || 'SI Category is required']"
                item-color="grey"
              ></v-select>
            </v-col>
            <v-col>
              <v-select
                :items="customerSIStatuses"
                v-model="customerSI.customerSIStatus_id"
                name="customerSIStatus"
                item-text="name"
                item-value="id"
                label="สถานะโครงการ"
                color="secondary"
                required
                :rules="[(v) => !!v || 'SI Status is required']"
                item-color="grey"
              ></v-select>
            </v-col>
            <v-col>
              <v-select
                :items="customerSIBudgetStatuses"
                v-model="customerSI.customerSIBudgetStatus_id"
                name="customerSIBudgetStatus"
                item-text="name"
                item-value="id"
                label="สถานะงบประมาณโครงการ"
                color="secondary"
                required
                :rules="[(v) => !!v || 'SI Budget Status is required']"
                item-color="grey"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xl="6" lg="6" md="12" sm="12" xs="12">
              <v-text-field
                v-model="customerSI.requestBudget"
                color="secondary"
                name="requestBudget"
                hint="งบประมาณที่ขอ"
                label="งบประมาณที่ขอ"
                type="number"
                :rules="requestBudgetRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xl="6" lg="6" md="12" sm="12" xs="12">
              <v-text-field
                v-model="customerSI.income"
                color="secondary"
                name="income"
                hint="รายได้ที่ได้รับ"
                label="รายได้ที่ได้รับ"
                type="number"
                :rules="incomeRules"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="customerSI.saleSector"
                color="secondary"
                name="saleSector"
                hint="ส่วนงานขาย"
                label="ส่วนงานขาย"
                clearable
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="customerSI.salePerson"
                color="secondary"
                name="salePerson"
                hint="เจ้าหน้าที่ขาย"
                label="เจ้าหน้าที่ขาย"
                clearable
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="customerSI.salePersonPhone"
                color="secondary"
                name="salePerson"
                hint="เบอร์ติดต่อเจ้าหน้าที่ขาย"
                label="เบอร์ติดต่อเจ้าหน้าที่ขาย"
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                v-model="customerSI.description"
                color="secondary"
                name="description"
                hint="รายละเอียดงาน"
                label="รายละเอียดงาน"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                v-model="customerSI.remark"
                color="secondary"
                name="remark"
                hint="หมายเหตุ"
                label="หมายเหตุ"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          class="body-2 font-weight-bold"
          outlined
          @click="save()"
          >save</v-btn
        >
        <v-btn
          color="grey"
          text
          class="body-2 font-weight-bold"
          @click="cancel()"
          >Cancel</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "CreateCustomerSIDialog",
  props: {},
  data() {
    return {
      id: null,
      dialog: false,
      resolve: null,
      reject: null,
      customerSICategories: null,
      customerSIStatuses: null,
      customerSIBudgetStatuses: null,
      customer: {
        name: null,
      },
      customerSI: {
        requestBudget: 0,
        income: 0,
      },
      options: {
        color: "primary",
        width: 400,
        zIndex: 200,
      },
      requestBudgetRules: [
        (value) => {
          if (!isNaN(parseFloat(value)) && value >= 0 && value <= 999999999)
            return true;
          return "Request budget has to be between 0 and 999,999,999";
        },
        (value) => value !== null || "Request Budget price is required",
      ],
      incomeRules: [
        (value) => {
          if (!isNaN(parseFloat(value)) && value >= 0 && value <= 999999999)
            return true;
          return "Income has to be between 0 and 999,999,999";
        },
        (value) => value !== null || "Income price is required",
      ],
    };
  },
  created() {
    this.getSICategories();
    this.getSIStatuses();
    this.getSIBudgetStatuses();
  },
  methods: {
    open: function (id) {
      this.dialog = true;
      this.id = id;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    getSICategories() {
      axios.get("/customer-si-category").then((response) => {
        this.customerSICategories = response.data;
      });
    },
    getSIStatuses() {
      axios.get("/customer-si-status").then((response) => {
        this.customerSIStatuses = response.data;
      });
    },
    getSIBudgetStatuses() {
      axios.get("/customer-si-budget-status").then((response) => {
        this.customerSIBudgetStatuses = response.data;
      });
    },
    save() {
      if (this.$refs.form.validate()) {
        axios
          .post(
            "/customer/" + this.id + "/customer-si",
            this.customerSI
          )
          .then((response) => {
            this.createCustomerSIResponse = response.data;
            if (this.createCustomerSIResponse.status === "success") {
              this.loading = false;
              this.resolve(true);
              this.dialog = false;
              this.$refs.form.reset();
              this.$emit("customer-si-created");
            }
          })
          .catch(function () {});
      }
    },
    cancel() {
      this.$refs.form.reset();
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>
