<template>
  <v-container>
    <div>
      <CreateCustomerCRMDataDialog
        ref="create"
        @customer-crm-data-created="getDataFromAPI"
      />
      <EditCustomerCRMDataDialog
        ref="edit"
        @customer-crm-data-edited="getDataFromAPI"
      />
      <ConfirmDeleteDialog ref="confirm" />
    </div>
    <v-card :loading="loading">
      <v-toolbar color="primary">
        <v-toolbar-title>
          <v-icon medium>
          {{  icon }}
        </v-icon>
        ข้อมูล CRM
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          small
          color="secondary"
          class="ma-2"
          outlined
          @click="openCreateCustomerCRMDataDialog(id)"
        >
          <v-icon small>
            mdi-plus
          </v-icon>
          Add CRM Data
        </v-btn>
      </v-toolbar>
      <!-- <v-card-title>
        <v-icon medium>
          {{  icon }}
        </v-icon>
        ข้อมูล CRM
        <v-spacer />
        <v-btn
          small
          color="secondary"
          class="ma-2"
          outlined
          @click="openCreateCustomerCRMDataDialog(id)"
        >
          <v-icon small>
            mdi-plus
          </v-icon>
          Add CRM Data
        </v-btn>
      </v-card-title>
      <v-divider /> -->
      <v-card-text v-if="loading == false && customerCRMDatas.length !== 0">
        <v-data-table
          :headers="headers"
          :items="customerCRMDatas"
          class="elevation-1"
          :footer-props="{ itemsPerPageOptions: [10, 50, 100] }"
          show-expand
          dense
        >
        <template v-slot:[`item.crmDatas.length`]="{ item }">
            <span>
              <v-chip small :color="getColor(item.crmDatas.length)" dark>
                {{ new Intl.NumberFormat("th").format(item.crmDatas.length) }}
              </v-chip>
            </span>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn icon @click="openEditCustomerCRMDataDialog(item)">
              <v-icon small color="secondary">mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon @click="confirmDeleteCustomerCRMData(item)">
              <v-icon small color="secondary">mdi-delete</v-icon>
            </v-btn>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length" class="pa-2">
                    <v-data-table
                      :headers="crmDatasHeaders"
                      :items="item.crmDatas"
                      :single-expand="true"
                      :items-per-page="-1"
                      hide-default-footer
                      dense
                      class="elevation-3"
                    >
                    </v-data-table>                   
                  </td>
                </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import CreateCustomerCRMDataDialog from "./CreateCustomerCRMDataDialog";
import EditCustomerCRMDataDialog from "./EditCustomerCRMDataDialog";
import ConfirmDeleteDialog from "../Shared/ConfirmDeleteDialog";

import { mdiFileTableBox } from '@mdi/js';

moment.locale("th");
export default {
  name: "CustomerCRMDataCard",
  components: {
    CreateCustomerCRMDataDialog,
    EditCustomerCRMDataDialog,
    ConfirmDeleteDialog,
    // CRMDataCard,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    id: {
      type: String,
    },
  },
  data() {
    return {
      icon: mdiFileTableBox,
      customerCRMDatas: {},
      loading: false,
      headers: [
        {
          text: "CA",
          value: "CA",
          sortable: false,
          align: "center",
        },
        {
          text: "BA",
          value: "BA",
          sortable: false,
          align: "center",
        },
        {
          text: "จำนวนบริการ",
          value: "crmDatas.length",
          sortable: false,
          align: "center",
        },
        {
          text: "",
          value: "action",
          sortable: false,
          align: "center",
        },
        // { text: "", value: "data-table-expand" },
      ],
      crmDatasHeaders: [
        {
          text: "Name",
          value: "name",
          sortable: false,
          align: "center",
        },
        {
          text: "Month",
          value: "month",
          sortable: false,
          align: "center",
        },
        {
          text: "Service Number",
          value: "serviceNum",
          sortable: false,
          align: "center",
        },
        {
          text: "Package",
          value: "bluePackage",
          sortable: false,
          align: "center",
        },
        {
          text: "Product",
          value: "blueProduct",
          sortable: false,
          align: "center",
        },
        {
          text: "Price",
          value: "price",
          sortable: false,
          align: "center",
        },
      ],
      customerSpecialBillDatasHeaders: [
        {
          text: "CA",
          value: "CA",
          sortable: false,
          align: "center",
        },
      ],
    };
  },
  created() {
    this.getDataFromAPI();
  },
  methods: {
    getDataFromAPI() {
      this.loading = true;
      axios
        .get("/customer/" + this.id + "/customer-crm-data")
        .then((response) => {
          // JSON responses are automatically parsed.
          this.customerCRMDatas = response.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    formatDate(date) {
      if (date !== null) {
        // return `${moment(date).format("LL")}`;
        return new Date(date).toLocaleDateString("th-TH", {
          dateStyle: "long",
        });
      }
      return null;
    },
    calExpireDateDiff(date) {
      if (date) {
        const now = moment(new Date());
        const duration = moment.duration(now.diff(date));
        const dayDiff = duration.asDays();
        if (dayDiff >= -30) {
          return true;
        } else {
          return false;
        }
      }
      return null;
    },
    openCreateCustomerCRMDataDialog: async function(id) {
      await this.$refs.create.open(id);
    },
    openEditCustomerCRMDataDialog: async function(customerCRMData) {
      await this.$refs.edit.open(customerCRMData);
    },
    confirmDeleteCustomerCRMData: async function(customerCRMData) {
      if (
        await this.$refs.confirm.open(
          "ยืนยันการลบข้อมูล CRM",
          "คุณแน่ใจที่จะลบข้อมูล CRM นี้?"
        )
      ) {
        this.deleteCustomerCRMData(customerCRMData);
      }
    },
    deleteCustomerCRMData: async function(customerCRMData) {
      this.loading = true;
      axios
        .delete("/customer-crm-data/" + customerCRMData.id)
        .then((response) => {
          // JSON responses are automatically parsed.
          this.deleteCustomerCRMDataResponse = response.data;
          this.loading = false;
          if (this.deleteCustomerCRMDataResponse.status === "success") {
            this.getDataFromAPI();
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getColor(number) {
      if (number === 0) return "grey";
      else return "green";
    },
  },
};
</script>

<style>
.scroll {
  overflow-y: scroll;
}
</style>
