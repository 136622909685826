<template>
  <v-container>
    <div>
      <CreateCustomerSIDialog
        ref="create"
        @customer-si-created="getDataFromAPI"
      />
      <EditCustomerSIDialog
        ref="edit"
        @customer-si-edited="getDataFromAPI"
      />
      <ConfirmDeleteDialog ref="confirm" />
    </div>
    <v-card :loading="loading">
      <v-toolbar color="primary">
        <v-toolbar-title>
          <v-icon medium>
            {{ icon }}
          </v-icon>
        งาน SI
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          small
          color="secondary"
          class="ma-2"
          outlined
          @click="openCreateCustomerSIDialog(id)"
        >
          <v-icon small>
            mdi-plus
          </v-icon>
          Add SI
        </v-btn>
      </v-toolbar>
      <!-- <v-card-title>
        <v-icon medium>
          mdi-handshake
        </v-icon>
        รายการเข้าพบลูกค้า
        <v-spacer />
        <v-btn
          small
          color="secondary"
          class="ma-2"
          outlined
          :to="'/customer/' + id + '/create-customer-meeting'" 
        >
          <v-icon small>
            mdi-plus
          </v-icon>
          Add Meeting
        </v-btn>
      </v-card-title>
      <v-divider /> -->
      <v-card-text v-if="loading == false && customerSIs.length !== 0">
        <v-data-table
          :headers="headers"
          :items="customerSIs"
          class="elevation-1"
          :footer-props="{ itemsPerPageOptions: [5] }"
        >
          <template v-slot:[`item.requestBudget`]="{ item }">
            <span>
              {{
                item.requestBudget !== 0
                  ? new Intl.NumberFormat("th", {
                      style: "currency",
                      currency: "THB",
                    }).format(item.requestBudget)
                  : "Free"
              }}
            </span>
          </template>
          <template v-slot:[`item.income`]="{ item }">
            <span>
              {{
                item.income !== 0
                  ? new Intl.NumberFormat("th", {
                      style: "currency",
                      currency: "THB",
                    }).format(item.income)
                  : "Free"
              }}
            </span>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn icon @click="openEditCustomerSIDialog(item)">
              <v-icon small color="secondary">mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon @click="confirmDeleteCustomerSI(item)">
              <v-icon small color="secondary">mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mdiCogs } from '@mdi/js';
import CreateCustomerSIDialog from "./CreateCustomerSIDialog";
import EditCustomerSIDialog from "./EditCustomerSIDialog";
import ConfirmDeleteDialog from "../Shared/ConfirmDeleteDialog";

moment.locale("th");
export default {
  name: "CustomerSICard",
  components: {
    CreateCustomerSIDialog,
    EditCustomerSIDialog,
    ConfirmDeleteDialog,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    id: {
      type: String,
    },
  },
  data() {
    return {
      icon: mdiCogs,
      customerSIs: {},
      loading: false,
      headers: [
        {
          text: "บริการ",
          value: "customerSICategory.name",
          sortable: false,
          align: "center",
        },
        {
          text: "รายละเอียด",
          value: "description",
          sortable: false,
          align: "center",
        },
        {
          text: "สถานะ",
          value: "customerSIStatus.name",
          sortable: false,
          align: "center",
        },
        {
          text: "สถานะงบประมาณ",
          value: "customerSIBudgetStatus.name",
          sortable: false,
          align: "center",
        },
        {
          text: "งบประมาณที่ขอ",
          value: "requestBudget",
          sortable: false,
          align: "center",
        },
        {
          text: "รายได้",
          value: "income",
          sortable: false,
          align: "center",
        },
        {
          text: "ส่วนงานขาย",
          value: "saleSector",
          sortable: false,
          align: "center",
        },
        {
          text: "พนักงานขาย",
          value: "salePerson",
          sortable: false,
          align: "center",
        },
        {
          text: "",
          value: "action",
          sortable: false,
          align: "center",
        },
      ],
    };
  },
  created() {
    this.getDataFromAPI();
  },
  methods: {
    getDataFromAPI() {
      this.loading = true;
      axios
        .get("/customer/" + this.id + "/customer-si")
        .then((response) => {
          // JSON responses are automatically parsed.
          this.customerSIs = response.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    formatDate(date) {
      if (date !== null) {
        // return `${moment(date).format("LL")}`;
        return new Date(date).toLocaleDateString("th-TH", {
          dateStyle: "long",
        });
      }
      return null;
    },
    openCreateCustomerSIDialog: async function(id) {
      await this.$refs.create.open(id);
    },
    openEditCustomerSIDialog: async function(customerSI) {
      await this.$refs.edit.open(customerSI);
    },
    confirmDeleteCustomerSI: async function(customerSI) {
      if (
        await this.$refs.confirm.open(
          "ยืนยันการลบรายการงาน SI",
          "คุณแน่ใจที่จะลบรายการงาน SI นี้?"
        )
      ) {
        this.deleteCustomerSI(customerSI);
      }
    },
    deleteCustomerSI: async function(customerSI) {
      this.loading = true;
      axios
        .delete("/customer-si/" + customerSI.id)
        .then((response) => {
          // JSON responses are automatically parsed.
          this.deleteCustomerSIResponse = response.data;
          this.loading = false;
          if (this.deleteCustomerSIResponse.status === "success") {
            this.getDataFromAPI();
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.scroll {
  overflow-y: scroll;
}
</style>
